import React from 'react'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Seo from '../components/seo'

import '../assets/scss/styles.scss'

function ImpressumPage() {
  return (
    <Layout>
      <Seo title="Helena's Kinderbuchparty - Widerrufserklärung" />
      <Navbar logoVisible={false} />
      <p>Widerrufserklärung</p>
    </Layout>
  )
}

export default ImpressumPage
